import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Image,
  View,
  Text,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  TextInput,
  FlatList,
} from "react-native";
import * as Linking from "expo-linking";
let Modal;

if (Platform.OS !== "web") {
  Modal = require("react-native").Modal;
} else {
  Modal = require("./Modal").default;
}

import * as ImagePicker from "expo-image-picker";
import * as Permissions from "expo-permissions";

import "@expo/match-media";
import { useMediaQuery } from "react-responsive";

import api from "../services/api";

export default function Gallery() {
  const Desktop = useMediaQuery({ minWidth: 1108 });
  const Tablet = useMediaQuery({ minWidth: 600, maxWidth: 1107 });
  const [image, setImage] = useState(null);
  const [email, setEmail] = useState("");

  const [pickerOptionsVisible, setPickerOptionsVisible] = useState(false);
  const [sendEmailVisible, setSendEmailVisible] = useState(false);
  const [sizeErrorVisible, setSizeErrorVisible] = useState(false);
  const [serverErrorVisible, setServerErrorVisible] = useState(false);
  const [repeatedInstagramErrorVisible, setRepeatedInstagramErrorVisible] =
    useState(false);

  const [successVisible, setSuccessVisible] = useState(false);
  const [imageArray, setImageArray] = useState([]);

  const [loadingSend, setLoadingSend] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await api.get("/download").then((resp) => {
          setImageArray(resp.data);
        });
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  function throwError(error) {
    switch (error) {
      case "size":
        setSizeErrorVisible(true);
        break;
      case "server":
        setServerErrorVisible(true);
        break;
      case "instagram":
        setRepeatedInstagramErrorVisible(true);
        break;
    }
  }

  const sendImage = async () => {
    try {
      await api
        .post("/upload", {
          email: "@" + email,
          //imgsource: image.base64,  //Mobile
          imgsource: image.uri.split(",")[1],
        })
        .then((resp) => {
          setSuccessVisible(true);
          setSendEmailVisible(false);
          setLoadingSend(false);
        });
    } catch (e) {
      setSendEmailVisible(false);
      setLoadingSend(false);
      if (e.response.status && e.response.status === 450) {
        throwError("instagram");
      } else {
        throwError("server");
      }
    }
  };

  const openInnerGallery = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });

    if (!result.cancelled) {
      if (result.width < 4032 && result.height < 4032) {
        setImage(result);
        setPickerOptionsVisible(false);
        setSendEmailVisible(true);
      } else {
        setPickerOptionsVisible(false);
        throwError("size");
      }
    }
  };

  const askForPermission = async () => {
    const permissionResult = await Permissions.askAsync(Permissions.CAMERA);
    if (permissionResult.status !== "granted") {
      Alert.alert("no permissions to access camera!", [{ text: "ok" }]);
      return false;
    }
    return true;
  };

  const openCamera = async () => {
    // make sure that we have the permission
    let hasPermission = false;
    if (Platform.OS === "web") {
      hasPermission = true;
    } else {
      hasPermission = await askForPermission();
    }

    if (!hasPermission) {
      return;
    } else {
      // launch the camera with the following settings
      let result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [3, 3],
        quality: 1,
        base64: true,
      });
      // make sure a image was taken:
      if (!result.cancelled) {
        if (result.width < 4032 && result.height < 4032) {
          setImage(result);
          setPickerOptionsVisible(false);
          setSendEmailVisible(true);
        } else {
          setPickerOptionsVisible(false);
          throwError("size");
        }
      }
    }
  };

  return (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <View
        style={[
          styles.gallery,
          Desktop ? { width: "100%" } : { width: "100%" },
        ]}
      >
        {imageArray.length == 0 && (
          <Image
            style={{
              width: Desktop ? 45 : 35,
              height: Desktop ? 45 : 35,
              resizeMode: "center",
              alignSelf: "center",
              marginTop: 15,
              padding: 0,
            }}
            source={require("../assets/spin.gif")}
          />
        )}

        {imageArray.length > 0 && (
          <FlatList
            data={[
              ...(Desktop ? imageArray.slice(0, 27) : imageArray.slice(0, 19)),
              { button: true },
            ]}
            renderItem={({ item }) => {
              if (item.button) {
                return (
                  <TouchableOpacity
                    key={item.id}
                    onPress={() => {
                      Linking.openURL(
                        "https://www.instagram.com/fadasveganasuai/"
                      );
                    }}
                  >
                    <Image
                      source={require("../assets/ver-mais_site_contador_uaitofu.png")}
                      style={
                        Desktop
                          ? { width: 120, height: 120, margin: 3 }
                          : { width: 85, height: 85, margin: 3 }
                      }
                    />
                  </TouchableOpacity>
                );
              }

              return (
                <TouchableOpacity key={item.id}>
                  <Image
                    source={{ uri: `data:image/gif;base64,${item.image}` }}
                    style={
                      Desktop
                        ? { width: 120, height: 120, margin: 3 }
                        : { width: 85, height: 85, margin: 3 }
                    }
                  />
                </TouchableOpacity>
              );
            }}
            numColumns={Desktop ? 7 : 4}
            key={Desktop ? 7 : 4}
          />
        )}
      </View>

      <Text
        style={{
          color: "#cc2419",
          fontSize: 16,
          fontFamily: "Lato_900Black",
          marginTop: 25,
        }}
      >
        Vem compartilhar o seu momento
      </Text>

      <Text
        style={{
          color: "#cc2419",
          fontSize: 16,
          fontFamily: "Lato_900Black",
        }}
      >
        #uailovers com a gente!
      </Text>

      <TouchableOpacity
        style={styles.button}
        onPress={() => {
          setPickerOptionsVisible(true);
        }}
      >
        <Text
          style={{
            color: "#cc2419",
            fontSize: 15,
            fontFamily: "Lato_900Black",
          }}
        >
          FAÇA AQUI O UPLOAD
        </Text>
        <Text
          style={{
            color: "#cc2419",
            fontSize: 15,
            fontFamily: "Lato_900Black",
          }}
        >
          DA SUA FOTO*
        </Text>
      </TouchableOpacity>

      <Modal
        animationType="slide"
        transparent={true}
        visible={pickerOptionsVisible}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <TouchableHighlight
              style={{ position: "absolute", top: 25, right: 25 }}
              onPress={() => {
                setPickerOptionsVisible(false);
              }}
            >
              <Image
                style={{
                  width: Desktop ? 40 : 38,
                  height: Desktop ? 40 : 38,
                  resizeMode: "center",
                  alignSelf: "flex-end",
                  padding: 0,
                }}
                source={require("../assets/designer/Elementos_destrinchados/X_site_uaitofu.png")}
              />
            </TouchableHighlight>

            <Text style={styles.modalText}>Selecione a sua</Text>
            <Text
              style={{ ...styles.modalText, marginBottom: 25, color: "#fff" }}
            >
              fotinha UAILOVER:
            </Text>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
              }}
            >
              <View>
                <TouchableHighlight
                  onPress={() => {
                    openInnerGallery();
                  }}
                >
                  <Image
                    style={{
                      width: Desktop ? 100 : 75,
                      height: Desktop ? 100 : 75,
                      resizeMode: "stretch",
                      alignSelf: "center",
                      padding: 0,
                    }}
                    source={require("../assets/designer/Elementos_destrinchados/galeria_site_uaitofu.png")}
                  />
                </TouchableHighlight>
                <Text style={styles.textStyle}>Galeria</Text>
              </View>

              <View>
                <TouchableHighlight
                  onPress={() => {
                    openCamera();
                  }}
                >
                  <Image
                    style={{
                      width: Desktop ? 100 : 75,
                      height: Desktop ? 100 : 75,
                      resizeMode: "stretch",
                      alignSelf: "center",
                      padding: 0,
                    }}
                    source={require("../assets/designer/Elementos_destrinchados/câmera_site_uaitofu.png")}
                  />
                </TouchableHighlight>
                <Text style={styles.textStyle}>Camera</Text>
              </View>
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        animationType="slide"
        transparent={true}
        visible={sendEmailVisible}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>Digite o seu instagram</Text>
            <TouchableHighlight
              style={{ position: "absolute", top: 25, right: 25 }}
              onPress={() => {
                setSendEmailVisible(false);
              }}
            >
              <Image
                style={{
                  width: Desktop ? 45 : 40,
                  height: Desktop ? 45 : 40,
                  resizeMode: "center",
                  alignSelf: "flex-end",
                  padding: 0,
                }}
                source={require("../assets/designer/Elementos_destrinchados/X_site_uaitofu.png")}
              />
            </TouchableHighlight>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ alignSelf: "center", color: "white" }}>@ </Text>

              <TextInput
                style={{
                  height: 40,
                  borderColor: "gray",
                  color: "#fff",
                  backgroundColor: "#555",
                  borderWidth: 0,
                  borderBottomWidth: 2,
                  marginTop: 5,
                  marginBottom: 10,
                }}
                onChangeText={(email) => setEmail(email)}
                value={email}
              />
            </View>
            {!loadingSend && (
              <TouchableHighlight
                style={styles.button}
                onPress={() => {
                  let validation = true;
                  let notEmptyValue = new RegExp(/\w+/);
                  let emailMask = new RegExp(
                    /([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/
                  );
                  let isSpace = new RegExp(/(?:\s+)/);
                  if (!notEmptyValue.test(email)) {
                    validation = false;
                    alert("Ops, digite um @");
                  }
                  if (!emailMask.test(email) || isSpace.test(email)) {
                    validation = false;
                    alert("Ops, digite um @ valido");
                  }

                  if (validation) {
                    sendImage();
                    setLoadingSend(true);
                  }
                }}
              >
                <Text style={styles.modalText}>Enviar</Text>
              </TouchableHighlight>
            )}
            {loadingSend && (
              <Image
                style={{
                  width: Desktop ? 45 : 35,
                  height: Desktop ? 45 : 35,
                  resizeMode: "center",
                  alignSelf: "center",
                  marginTop: 15,
                  padding: 0,
                }}
                source={require("../assets/spin_gray.gif")}
              />
            )}
          </View>
        </View>
      </Modal>

      <Modal
        animationType="slide"
        transparent={true}
        visible={sizeErrorVisible}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>
              Ops, esta imagem é muito grande.
            </Text>

            <TouchableHighlight
              style={[styles.button, { backgroundColor: "#222" }]}
              onPress={() => {
                setSizeErrorVisible(false);
              }}
            >
              <Text style={styles.modalText}>Voltar</Text>
            </TouchableHighlight>
          </View>
        </View>
      </Modal>

      <Modal
        animationType="slide"
        transparent={true}
        visible={serverErrorVisible}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>Ops, algo deu errado.</Text>

            <Text style={styles.modalText}>
              Tente novamente em alguns instantes.
            </Text>

            <TouchableHighlight
              style={[styles.button, { backgroundColor: "#222" }]}
              onPress={() => {
                setServerErrorVisible(false);
              }}
            >
              <Text style={styles.modalText}>Voltar</Text>
            </TouchableHighlight>
          </View>
        </View>
      </Modal>

      <Modal
        animationType="slide"
        transparent={true}
        visible={repeatedInstagramErrorVisible}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>
              Ops, este instagram já foi cadastrado.
            </Text>

            <TouchableHighlight
              style={[styles.button, { backgroundColor: "#222" }]}
              onPress={() => {
                setRepeatedInstagramErrorVisible(false);
              }}
            >
              <Text style={styles.modalText}>Voltar</Text>
            </TouchableHighlight>
          </View>
        </View>
      </Modal>

      <Modal animationType="slide" transparent={true} visible={successVisible}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>Imagem enviada com sucesso :)</Text>

            <TouchableHighlight
              style={styles.button}
              onPress={() => {
                setSuccessVisible(false);
              }}
            >
              <Text style={styles.modalText}>Voltar</Text>
            </TouchableHighlight>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "#555",
    width: 300,
    height: 300,
    borderRadius: 35,
    padding: 35,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    elevation: 5,
    opacity: 1,
  },
  button: {
    backgroundColor: "#f9be2b",
    alignItems: "center",
    marginTop: 25,
    padding: 10,
    paddingLeft: 40,
    paddingRight: 40,
    borderRadius: 40,
  },
  loader: {
    alignItems: "center",
    marginTop: 25,
    padding: 10,
    paddingLeft: 40,
    paddingRight: 40,
    borderRadius: 40,
  },
  textStyle: {
    color: "white",
    fontFamily: "Lato_900Black",
    textAlign: "center",
  },
  modalText: {
    textAlign: "center",
    color: "#fff",
    fontFamily: "Lato_900Black",
    fontSize: 15,
  },
  gallery: {
    display: "flex",
    justifyContent: "center",
    alignContents: "center",
  },
});
