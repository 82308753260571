import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Image,
  View,
  TouchableOpacity,
  FlatList
} from "react-native";

import * as Linking from "expo-linking";

import "@expo/match-media";
import { useMediaQuery } from "react-responsive";

export default function Partners() {
  const Desktop = useMediaQuery({ minWidth: 768 });
  const imageArray = [
    {
      id: 0,
      instagram: "https://www.instagram.com/mercatoverde/",
      uri: require("../assets/marcas/marcassiteuaitifu_quadrado_Prancheta-01.png")
    },
    {
      id: 1,
      instagram: "https://www.instagram.com/bvegan.gastronomia/",
      uri: require("../assets/marcas/marcassiteuaitifu_quadrado-07.png")
    },

    {
      id: 2,
      instagram: "https://www.instagram.com/littleveganstore/",
      uri: require("../assets/marcas/marcassiteuaitifu_quadrado-08.png")
    },

    {
      id: 3,
      instagram: "https://www.instagram.com/naturalecolife/",
      uri: require("../assets/marcas/marcassiteuaitifu_quadrado-06.png")
    },

    {
      id: 4,
      instagram: "https://www.instagram.com/multveg/",
      uri: require("../assets/marcas/marcassiteuaitifu_quadrado-04.png")
    },

    {
      id: 5,
      instagram: "https://www.instagram.com/quintal_vegan/",
      uri: require("../assets/marcas/marcassiteuaitifu_quadrado-02.png")
    },

    {
      id: 6,
      instagram: "https://www.instagram.com/carolvfood/",
      uri: require("../assets/marcas/marcassiteuaitifu_quadrado-05.png")
    },

    {
      id: 7,
      instagram: "https://www.instagram.com/happigveg/",
      uri: require("../assets/marcas/marcassiteuaitifu_quadrado-03.png")
    },

    {
      id: 8,
      instagram: "https://www.instagram.com/santavegana/",
      uri: require("../assets/marcas/marcassiteuaitifu_quadrado-09.png")
    },
  ];

  return (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <View
        style={[styles.gallery, Desktop ? { width: "100%" } : { width: "100%" }]}
      >

        {imageArray.length > 0 &&
        <FlatList
          data={imageArray} renderItem={({item}) => (       

            <TouchableOpacity 
              key={item.id}
              onPress={() => {
                Linking.openURL(
                  item.instagram
                );
              }}
            >
              {console.log(item)}
              <Image
                source={item.uri}
                style={
                  Desktop
                    ? { width: 160, height: 160, margin: 1 }
                    : { width: 100, height: 100, margin: 1 }
                }
              />
            </TouchableOpacity>

          )}
         numColumns = {3} 
         key = {3} />
        }


      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  gallery: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
  },
});
