import React from "react";
import { View } from "react-native";

export default function ColorItem(props) {
  const colors = {
    "red-yellow": "#000",
    red: "#cc2419",
    yellow: "#fabf2b",
    brown: "#996d3c",
    black: "#1d1d1b",
    green: "#3b4d35",
    white: "#fff",
  };

  return (
    <View
      style={{
        width: 40,
        height: 40,
        backgroundColor: colors[props.color],
        borderRadius: "50%",
        borderWidth: 2,
        marginRight: 10,
        marginBottom: 10,
        overflow: "hidden",
      }}
    >
      {props.children}
    </View>
  );
}
