import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  Platform,
} from "react-native";

import { StatusBar } from "expo-status-bar";
import {
  useFonts,
  Lato_400Regular,
  Lato_900Black,
  Lato_700Bold_Italic,
} from "@expo-google-fonts/lato";

import * as Linking from "expo-linking";

import "@expo/match-media";
import { useMediaQuery } from "react-responsive";

import Artes from "../components/Artes";

export default function Logos() {
  const Tablet = useMediaQuery({ minWidth: 1250 });
  const Desktop = useMediaQuery({ minWidth: 876 });

  let [fontsLoaded] = useFonts({
    Lato_400Regular,
    Lato_900Black,
    Lato_700Bold_Italic,
  });

  return (
    <View style={styles.container}>
      {Platform.OS !== "web" && (
        <StatusBar style="light" backgroundColor="#333" />
      )}

      <ScrollView style={{ width: "100%", height: "100%" }}>
        <View style={styles.parceiros}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 25,
              marginLeft: 55,
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                paddingTop: Desktop ? 80 : 20,
                paddingRight: 10,
              }}
            >
              <Image
                style={{
                  width: Desktop ? 315 : 180,
                  height: Desktop ? 33 : 18,
                  resizeMode: "stretch",
                  margin: 0,
                  padding: 0,
                }}
                source={require("../assets/logomarcas/uailogos.png")}
              />
              <Text
                style={{
                  color: "#fabf2b",
                  fontSize: Desktop ? 20 : 15,
                  fontFamily: "Lato_900Black",
                  margin: 0,
                  marginTop: Desktop ? 5 : 0,
                  padding: 0,
                  paddingLeft: 10,
                  paddingTop: 10,
                }}
              >
                Selecione uma imagem 
              </Text>
              <Text
                style={{
                  color: "#fabf2b",
                  fontSize: Desktop ? 15 : 10,
                  fontFamily: "Lato_900Black",
                  margin: 0,
                  marginTop: Desktop ? 5 : 0,
                  padding: 0,
                  paddingLeft: 10,
                }}
              >
                para configurar e baixar
              </Text>
            </View>

            <Image
              style={{
                width: Desktop ? 66 : 45,
                height: Desktop ? 66 : 45,
                resizeMode: "contain",
              }}
              source={require("../assets/designer/Elementos_destrinchados/coracoes_site_uaitofu.png")}
            />
          </View>

          <Artes />
        </View>

        <View
          style={{
            backgroundColor: "#fff",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              color: "#444",
              fontSize: 10,
              fontFamily: "Lato_400Regular",
              textAlign: "center",
              width: "80%",
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Magicamente desenvolvido por{" "}
            <TouchableOpacity
              onPress={() => {
                Linking.openURL("https://www.arcanoprojetos.com");
              }}
            >
              <Text
                style={{
                  color: "#855",
                  fontFamily: "Lato_900Black",
                }}
              >
                Arcano Projetos
              </Text>
            </TouchableOpacity>
            {""} e {""}
            <TouchableOpacity
              onPress={() => {
                Linking.openURL("https://www.instagram.com/designpolen/");
              }}
            >
              <Text
                style={{
                  color: "#855",
                  fontFamily: "Lato_900Black",
                }}
              >
                Pólen Design
              </Text>
            </TouchableOpacity>
          </Text>
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#cc2419",
    alignItems: "center",
  },
  landpage: {
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#cc2419",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "justify",
    paddingBottom: 50,
  },
  counter: {
    width: "100%",
    height: 650,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  days: {
    width: "100%",
    backgroundColor: "#986d3c",
    alignItems: "center",
    justifyContent: "center",
  },
  uailovers: {
    width: "100%",
    paddingTop: 25,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  parceiros: {
    width: "100%",
    paddingTop: 50,
    paddingBottom: 100,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  footer: {
    width: "100%",
    backgroundColor: "#cc2419",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    height: 320,
  },
});
