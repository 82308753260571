import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Image,
  View,
  TouchableOpacity,
  FlatList,
  Platform,
  Text,
  ImageBackground,
} from "react-native";

import "@expo/match-media";
import { useMediaQuery } from "react-responsive";
import ColorItem from "./ColorItem";

import fullColor from "../assets/logomarcas/fullcolorDetail.png";

export default function Partners() {
  const Desktop = useMediaQuery({ minWidth: 768 });
  let Modal;
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});
  const [editImage, setEditImage] = useState();
  const [downloadImage, setDownloadImage] = useState();

  if (Platform.OS !== "web") {
    Modal = require("react-native").Modal;
  } else {
    Modal = require("./ModalArtes").default;
  }

  const imageArray = [
    {
      id: 0,
      name: "logo_horizontal",
      downloadFull: require("../assets/logomarcas/logotipo/horizontal/download/logo_horizontal_uaitofu_colorido.png"),
      downloadAmarelo: require("../assets/logomarcas/logotipo/horizontal/download/logo_horizontal_uaitofu_amarelo.png"),
      downloadBranco: require("../assets/logomarcas/logotipo/horizontal/download/logo_horizontal_uaitofu_branco.png"),
      downloadMarrom: require("../assets/logomarcas/logotipo/horizontal/download/logo_horizontal_uaitofu_marrom.png"),
      downloadPreto: require("../assets/logomarcas/logotipo/horizontal/download/logo_horizontal_uaitofu_preto.png"),
      downloadVerde: require("../assets/logomarcas/logotipo/horizontal/download/logo_horizontal_uaitofu_verde.png"),
      downloadVermelho: require("../assets/logomarcas/logotipo/horizontal/download/logo_horizontal_uaitofu_vermelho.png"),

      displayFull: require("../assets/logomarcas/logotipo/horizontal/logo_horizontal_uaitofu_colorido.png"),
      displayAmarelo: require("../assets/logomarcas/logotipo/horizontal/logo_horizontal_uaitofu_amarelo.png"),
      displayBranco: require("../assets/logomarcas/logotipo/horizontal/logo_horizontal_uaitofu_branco.png"),
      displayMarrom: require("../assets/logomarcas/logotipo/horizontal/logo_horizontal_uaitofu_marrom.png"),
      displayPreto: require("../assets/logomarcas/logotipo/horizontal/logo_horizontal_uaitofu_preto.png"),
      displayVerde: require("../assets/logomarcas/logotipo/horizontal/logo_horizontal_uaitofu_verde.png"),
      displayVermelho: require("../assets/logomarcas/logotipo/horizontal/logo_horizontal_uaitofu_vermelho.png"),
    },

    {
      id: 1,
      name: "carinha",
      downloadFull: require("../assets/logomarcas/icone/download/logo_carinha_uaitofu_vermelho.png"),
      downloadVermelho: require("../assets/logomarcas/icone/download/logo_carinha_uaitofu_vermelho.png"),
      downloadAmarelo: require("../assets/logomarcas/icone/download/logo_carinha_uaitofu_amarelo.png"),
      downloadBranco: require("../assets/logomarcas/icone/download/logo_carinha_uaitofu_branco.png"),
      downloadPreto: require("../assets/logomarcas/icone/download/logo_carinha_uaitofu_preto.png"),
      downloadVerde: require("../assets/logomarcas/icone/download/logo_carinha_uaitofu_verde.png"),

      displayFull: require("../assets/logomarcas/icone/logo_carinha_uaitofu_vermelho.png"),
      displayVermelho: require("../assets/logomarcas/icone/logo_carinha_uaitofu_vermelho.png"),
      displayAmarelo: require("../assets/logomarcas/icone/logo_carinha_uaitofu_amarelo.png"),
      displayBranco: require("../assets/logomarcas/icone/logo_carinha_uaitofu_branco.png"),
      displayPreto: require("../assets/logomarcas/icone/logo_carinha_uaitofu_preto.png"),
      displayVerde: require("../assets/logomarcas/icone/logo_carinha_uaitofu_verde.png"),
    },

    {
      id: 2,
      name: "logo_vertical",
      downloadFull: require("../assets/logomarcas/logotipo/vertical/download/logo_vertical_uaitofu_colorido.png"),
      downloadAmarelo: require("../assets/logomarcas/logotipo/vertical/download/logo_vertical_uaitofu_amarelo.png"),
      downloadBranco: require("../assets/logomarcas/logotipo/vertical/download/logo_vertical_uaitofu_branco.png"),
      downloadPreto: require("../assets/logomarcas/logotipo/vertical/download/logo_vertical_uaitofu_preto.png"),
      downloadVerde: require("../assets/logomarcas/logotipo/vertical/download/logo_vertical_uaitofu_verde.png"),
      downloadVermelho: require("../assets/logomarcas/logotipo/vertical/download/logo_vertical_uaitofu_vermelho.png"),

      displayFull: require("../assets/logomarcas/logotipo/vertical/logo_vertical_uaitofu_colorido.png"),
      displayAmarelo: require("../assets/logomarcas/logotipo/vertical/logo_vertical_uaitofu_amarelo.png"),
      displayBranco: require("../assets/logomarcas/logotipo/vertical/logo_vertical_uaitofu_branco.png"),
      displayPreto: require("../assets/logomarcas/logotipo/vertical/logo_vertical_uaitofu_preto.png"),
      displayVerde: require("../assets/logomarcas/logotipo/vertical/logo_vertical_uaitofu_verde.png"),
      displayVermelho: require("../assets/logomarcas/logotipo/vertical/logo_vertical_uaitofu_vermelho.png"),
    },

    {
      id: 3,
      name: "logo_vertical_background_amarelo",
      downloadFull: require("../assets/logomarcas/logotipo/vertical/download/logo_vertical_uaitofu_background_amarelo.png"),
      displayFull: require("../assets/logomarcas/logotipo/vertical/logo_vertical_uaitofu_background_amarelo.png"),
    },

    {
      id: 4,
      name: "logo_vertical_background_vermelho",
      downloadFull: require("../assets/logomarcas/logotipo/vertical/download/logo_vertical_uaitofu_background_vermelho.png"),
      displayFull: require("../assets/logomarcas/logotipo/vertical/logo_vertical_uaitofu_background_vermelho.png"),
    },

    {
      id: 5,
      name: "logo_ai",
      downloadFull: require("../assets/logomarcas/logotipo/marca_uaitofu.ai"),
      displayFull: require("../assets/logomarcas/logotipo/adobe_ilustrator_logo.png"),
    },
  ];

  return (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <View
        style={[
          styles.gallery,
          Desktop ? { width: "100%" } : { width: "100%" },
        ]}
      >
        {imageArray.length > 0 && (
          <FlatList
            data={imageArray}
            renderItem={({ item }) => (
              <TouchableOpacity
                key={item.id}
                onPress={async () => {
                  setSelectedImage(item);
                  setEditImage(item.displayFull);
                  setDownloadImage(item.downloadFull);
                  setShowModal(true);
                }}
              >
                <Image
                  source={item.displayFull}
                  style={[
                    Desktop
                      ? {
                          width: 160,
                          height: 160,
                        }
                      : {
                          width: 100,
                          height: 100,
                        },
                    styles.imageButtons,
                  ]}
                />
              </TouchableOpacity>
            )}
            numColumns={3}
            key={3}
          />
        )}
      </View>
      <Modal animationType="slide" transparent={true} visible={showModal}>
        <View style={styles.centeredView}>
          <View
            style={[
              styles.modalView,
              { width: Desktop ? 500 : 350, height: Desktop ? 450 : 350 },
            ]}
          >
            <TouchableOpacity
              style={{ position: "absolute", top: 25, right: 25, zIndex: 100 }}
              onPress={() => {
                setShowModal(false);
              }}
            >
              <Image
                style={{
                  width: Desktop ? 45 : 38,
                  height: Desktop ? 45 : 38,
                  resizeMode: "center",
                  alignSelf: "flex-end",
                  padding: 0,
                }}
                source={require("../assets/designer/Elementos_destrinchados/X_site_uaitofu.png")}
              />
            </TouchableOpacity>
            <View>
              <Image
                style={[
                  {
                    width: Desktop ? 300 : 180,
                    height: Desktop ? 300 : 180,
                    resizeMode: "center",
                    alignSelf: "center",
                    padding: 0,
                  },
                  selectedImage.name === "logo_horizontal"
                    ? Desktop
                      ? { transform: "translate(0, 50px)" }
                      : { transform: "translate(0, 30px)" }
                    : { transform: "translate(0, 0)" },
                ]}
                source={editImage}
              />
              <View>
                <View style={{ justifyContent: "center", width: "100%" }}>
                  {selectedImage.name !== "logo_vertical_background_amarelo" &&
                    selectedImage.name !==
                      "logo_vertical_background_vermelho" &&
                    selectedImage.name !== "logo_ai" && (
                      <>
                        <Text
                          style={{
                            color: "#fff",
                            fontSize: 15,
                            fontFamily: "Lato_900Black",
                            marginBottom: 5,
                          }}
                        >
                          ESCOLHA A COR
                        </Text>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap-reverse",
                            padding: 10,
                          }}
                        >
                          {selectedImage.name !== "carinha" && (
                            <TouchableOpacity
                              onPress={() => {
                                setEditImage(selectedImage.displayFull);
                                setDownloadImage(selectedImage.downloadFull);
                              }}
                            >
                              <ColorItem background="red-yellow">
                                <ImageBackground
                                  source={fullColor}
                                  style={{
                                    resizeMode: "cover",
                                    justifyContent: "center",
                                    flex: 1,
                                  }}
                                />
                              </ColorItem>
                            </TouchableOpacity>
                          )}

                          <TouchableOpacity
                            onPress={() => {
                              setEditImage(selectedImage.displayVermelho);
                              setDownloadImage(selectedImage.downloadVermelho);
                            }}
                          >
                            <ColorItem color="red" />
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              setEditImage(selectedImage.displayAmarelo);
                              setDownloadImage(selectedImage.downloadAmarelo);
                            }}
                          >
                            <ColorItem color="yellow" />
                          </TouchableOpacity>
                          {selectedImage.name === "logo_horizontal" && (
                            <TouchableOpacity
                              onPress={() => {
                                setEditImage(selectedImage.displayMarrom);
                                setDownloadImage(selectedImage.downloadMarrom);
                              }}
                            >
                              <ColorItem color="brown" />
                            </TouchableOpacity>
                          )}

                          <TouchableOpacity
                            onPress={() => {
                              setEditImage(selectedImage.displayPreto);
                              setDownloadImage(selectedImage.downloadPreto);
                            }}
                          >
                            <ColorItem color="black" />
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              setEditImage(selectedImage.displayVerde);
                              setDownloadImage(selectedImage.downloadVerde);
                            }}
                          >
                            <ColorItem color="green" />
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              setEditImage(selectedImage.displayBranco);
                              setDownloadImage(selectedImage.downloadBranco);
                            }}
                          >
                            <ColorItem color="white" />
                          </TouchableOpacity>
                        </View>
                      </>
                    )}
                  <a
                    href={downloadImage}
                    download={selectedImage.name}
                    style={
                      selectedImage.name !==
                        "logo_vertical_background_amarelo" &&
                      selectedImage.name !==
                        "logo_vertical_background_vermelho" &&
                      selectedImage.name !== "logo_ai"
                        ? { "text-decoration": "none" }
                        : { "text-decoration": "none", alignSelf: "center" }
                    }
                  >
                    <TouchableOpacity
                      style={[styles.button]}
                      onPress={() => {
                        console.log("baixando");
                      }}
                    >
                      <Text
                        style={{
                          color: "#cc2419",
                          fontSize: 15,
                          fontFamily: "Lato_900Black",
                        }}
                      >
                        BAIXAR
                      </Text>
                    </TouchableOpacity>
                  </a>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  gallery: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
  },
  centeredView: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "#555",
    borderRadius: 35,
    padding: 35,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    elevation: 5,
  },
  button: {
    backgroundColor: "#f9be2b",
    alignItems: "center",
    width: 130,
    marginBottom: 25,
    padding: 10,
    paddingLeft: 40,
    paddingRight: 40,
    borderRadius: 40,
  },
  imageButtons: {
    borderWidth: 3,
    borderRadius: 5,
    borderColor: "#ddd",
    margin: 5,
    resizeMode: "contain",
  },
});
