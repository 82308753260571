import "react-native-gesture-handler";
import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

import "@expo/match-media";
import AbracandoFadas from "./pages/AbracandoFadas";
import Logos from "./pages/Logos";

const Stack = createStackNavigator();
import * as Linking from 'expo-linking';

const prefix = Linking.createURL('/logos');

const config = {
    Home: '',
    Logos: 'logos',
};

const linking = {
  prefixes: ['https://mychat.com'],
  config,
};

export default function App() {
  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        <Stack.Screen name="Home" component={Logos} />
        <Stack.Screen name="Logos" component={Logos} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
