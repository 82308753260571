import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  Platform,
} from "react-native";

import { StatusBar } from "expo-status-bar";
import {
  useFonts,
  Lato_400Regular,
  Lato_900Black,
  Lato_700Bold_Italic,
} from "@expo-google-fonts/lato";

import * as Linking from "expo-linking";

import "@expo/match-media";
import { useMediaQuery } from "react-responsive";

import api from "../services/api";

import LandPageDesktop from "../components/LandPageDesktop";
import LandPageMobile from "../components/LandPageMobile";
import Counter from "../components/Counter";
import Days from "../components/Days";
import Gallery from "../components/Gallery";
import Partners from "../components/Partners";

export default function AbracandoFadas() {
  const Tablet = useMediaQuery({ minWidth: 1250 });
  const Desktop = useMediaQuery({ minWidth: 876 });

  let [fontsLoaded] = useFonts({
    Lato_400Regular,
    Lato_900Black,
    Lato_700Bold_Italic,
  });

  const [tofuVendido, setTofuVendido] = useState(0);
  const [dinheiroRevertido, setDinheiroRevertido] = useState(0);
  const [diasPassados, setDiasPassados] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        await api.get("/counter").then((resp) => {
          setTofuVendido(resp.data.tofu_vendido);
          setDinheiroRevertido(resp.data.valor_doado + 2000 + 254,50);
          var now = new Date();
          var start = new Date(now.getFullYear(), 0, 0);
          var diff =
            now -
            start +
            (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
          var oneDay = 1000 * 60 * 60 * 24;
          var day = Math.floor(diff / oneDay);
          setDiasPassados(day - 1);
        });
      } catch (e) {
        console.log("Error");
      }
    }
    fetchData();
  }, []);

  return (
    <View style={styles.container}>
      {Platform.OS !== "web" && (
        <StatusBar style="light" backgroundColor="#333" />
      )}

      <ScrollView style={{ width: "100%", height: "100%" }}>
        {Desktop && (
          <View style={styles.landpage}>
            <LandPageDesktop />
          </View>
        )}

        {!Desktop && (
          <View style={styles.landpage}>
            <LandPageMobile />
          </View>
        )}

        <View style={[styles.counter, Desktop ? { paddingRight: 90 } : {}]}>
          <Counter
            tofuVendido={tofuVendido}
            dinheiroRevertido={dinheiroRevertido}
          />
        </View>

        <View
          style={[styles.days, Desktop ? { height: 450 } : { height: 350 }]}
        >
          <Days diasPassados={diasPassados} />
        </View>

        <View style={styles.uailovers}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 25,
              marginLeft: 55,
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                paddingTop: Desktop ? 30 : 20,
                paddingRight: 10,
              }}
            >
              <Image
                style={{
                  width: Desktop ? 245 : 150,
                  height: Desktop ? 28 : 20,
                  resizeMode: "stretch",
                  margin: 0,
                  padding: 0,
                }}
                source={require("../assets/designer/Elementos_destrinchados/uailovers_site_uaitofu.png")}
              />
              <Text
                style={{
                  color: "#cc2419",
                  fontSize: Desktop ? 33 : 21,
                  fontFamily: "Lato_900Black",
                  margin: 0,
                  padding: 0,
                }}
              >
                que já apoiaram
              </Text>
            </View>

            <Image
              style={{
                width: Desktop ? 66 : 45,
                height: Desktop ? 66 : 45,
                resizeMode: "contain",
              }}
              source={require("../assets/designer/Elementos_destrinchados/coracoes_site_uaitofu.png")}
            />
          </View>

          <Gallery />
        </View>

        <View style={styles.parceiros}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 25,
              marginLeft: 55,
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                paddingTop: Desktop ? 80 : 20,
                paddingRight: 10,
              }}
            >
              <Image
                style={{
                  width: Desktop ? 320 : 180,
                  height: Desktop ? 26 : 18,
                  resizeMode: "stretch",
                  margin: 0,
                  padding: 0,
                }}
                source={require("../assets/designer/uaiparceiros_site_uaitofu.png")}
              />
              <Text
                style={{
                  color: "#cc2419",
                  fontSize: Desktop ? 31 : 18,
                  fontFamily: "Lato_900Black",
                  margin: 0,
                  marginTop: Desktop ? 5 : 0,
                  padding: 0,
                  paddingLeft: 10,
                }}
              >
                que já apoiaram
              </Text>
            </View>

            <Image
              style={{
                width: Desktop ? 66 : 45,
                height: Desktop ? 66 : 45,
                resizeMode: "contain",
              }}
              source={require("../assets/designer/Elementos_destrinchados/coracoes_site_uaitofu.png")}
            />
          </View>

          <Partners />
        </View>

        <View style={styles.footer}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 15,
            }}
          >
            {Desktop && (
              <>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL(
                      "https://instagram.com/santuario_das_fadas?igshid=lufwncmt23jm"
                    );
                  }}
                >
                  <Image
                    style={{
                      position: "absolute",
                      top: 0,
                      left: "calc(50vw - 440px)",
                      width: 500,
                      height: 165,
                      transform: Tablet
                        ? [{ translateY: "-50%" }]
                        : [{ translateY: "-50%" }],
                      resizeMode: "contain",
                    }}
                    source={require("../assets/designer/Elementos_destrinchados/partes_desktop_site_contador.png")}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL(
                      "https://instagram.com/uaitofu?igshid=7n8jvqkhrahr"
                    );
                  }}
                >
                  <Image
                    style={{
                      position: "absolute",
                      top: 0,
                      right: "calc(50vw - 440px)",
                      width: 500,
                      height: 165,
                      transform: Tablet
                        ? [{ translateY: "-50%" }]
                        : [{ translateY: "-50%" }],
                      resizeMode: "contain",
                    }}
                    source={require("../assets/designer/Elementos_destrinchados/partes_desktop_site_contador_uai.png")}
                  />
                </TouchableOpacity>
              </>
            )}

            {!Desktop && (
              <>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL(
                      "https://instagram.com/santuario_das_fadas?igshid=lufwncmt23jm"
                    );
                  }}
                >
                  <Image
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: 180,
                      height: 110,
                      transform: [{ translateY: "-50%" }],
                      resizeMode: "contain",
                    }}
                    source={require("../assets/designer/Elementos_destrinchados/conheça_santuário_site_uaitofu.png")}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL(
                      "https://instagram.com/uaitofu?igshid=7n8jvqkhrahr"
                    );
                  }}
                >
                  <Image
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      width: 180,
                      height: 120,
                      transform: [{ translateY: "-50%" }],
                      resizeMode: "contain",
                    }}
                    source={require("../assets/designer/Elementos_destrinchados/conheça_uai_site_uaitofu.png")}
                  />
                </TouchableOpacity>
              </>
            )}
          </View>
          <Image
            style={{
              position: "absolute",
              top: 50,
              width: 280,
              height: 280,
              resizeMode: "contain",
              alignSelf: "center",
              backgroundColor: "transparent",
            }}
            source={require("../assets/designer/Elementos_destrinchados/brasão_site_uaitofu.png")}
          />
        </View>
        <View
          style={{
            backgroundColor: "#fff",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              color: "#333",
              fontSize: 12,
              textAlign: "center",
              fontFamily: "Lato_400Regular",
              width: "80%",
              marginTop: 15,
              marginBottom: 5,
            }}
          >
            *Ao nos enviar a sua imagem, você estará concorrendo automaticamente
            a sorteios e promoções vinculados a está campanha e também nos
            cedendo os direitos de uso da imagem no site e redes sociais
            vinculadas à UAI Tofu.
          </Text>
          <Text
            style={{
              color: "#444",
              fontSize: 10,
              fontFamily: "Lato_400Regular",
              textAlign: "center",
              width: "80%",
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Magicamente desenvolvido por{" "}
            <TouchableOpacity
              onPress={() => {
                Linking.openURL("https://www.arcanoprojetos.com");
              }}
            >
              <Text
                style={{
                  color: "#855",
                  fontFamily: "Lato_900Black",
                }}
              >
                Arcano Projetos
              </Text>
            </TouchableOpacity>
            {""} e {""}
            <TouchableOpacity
              onPress={() => {
                Linking.openURL("https://www.instagram.com/designpolen/");
              }}
            >
              <Text
                style={{
                  color: "#855",
                  fontFamily: "Lato_900Black",
                }}
              >
                Pólen Design
              </Text>
            </TouchableOpacity>
          </Text>
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#cc2419",
    alignItems: "center",
  },
  landpage: {
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#cc2419",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "justify",
    paddingBottom: 50,
  },
  counter: {
    width: "100%",
    height: 650,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  days: {
    width: "100%",
    backgroundColor: "#986d3c",
    alignItems: "center",
    justifyContent: "center",
  },
  uailovers: {
    width: "100%",
    paddingTop: 25,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  parceiros: {
    width: "100%",
    paddingTop: 50,
    paddingBottom: 100,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  footer: {
    width: "100%",
    backgroundColor: "#cc2419",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    height: 320,
  },
});
